import { Link } from "react-router-dom";
import "../styles/main.css";

const Main = () => {
  return (
    <div className="main">
      <h2>Lessons</h2>
      <div className="link-group">
        <Link to="video/17">Block 17</Link>
        <Link to="transcript/17">Transcript</Link>
      </div>
      <div className="link-group">
        <Link to="video/18">Block 18</Link>
        <Link to="transcript/18">Transcript</Link>
      </div>
      <div className="link-group">
        <Link to="video/19">Block 19</Link>
        <Link to="transcript/19">Transcript</Link>
      </div>
      <div className="link-group">
        <Link to="video/20">Block 20</Link>
        <Link to="transcript/20">Transcript</Link>
      </div>
    </div>
  );
};

export default Main;
