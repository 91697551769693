import "../styles/video.css";
import * as transcripts from "../transcripts";
import { useParams } from "react-router-dom";
import "../styles/transcript.css";

const Transcript = (props) => {
  let { id } = useParams();

  let blockVar = "block_" + id;
  const transcript = transcripts[blockVar];

  return (
    <div className="transcript-solo">
      <h2>Transcript: Block {id}</h2>
      {transcript.text}
    </div>
  );
};

export default Transcript;
