import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Video from "./components/Video";
import Transcript from "./components/Transcript";
import Main from "./components/Main";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/video/:id" element={<Video />} />
          <Route path="/transcript/:id" element={<Transcript />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
