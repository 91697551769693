import "../styles/video.css";
import * as transcripts from "../transcripts";
// import * as videos from "../videos";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

const Video = (props) => {
  const videoBaseUrl = "https://pub-c64220ef36154a48a4987a228906438d.r2.dev";
  const videoRef = useRef(null);
  let { id } = useParams();

  let blockVar = "block_" + id;
  const transcript = transcripts[blockVar];
  const segments = transcript?.segments;

  const video = `${videoBaseUrl}/block_${id}.mp4`;

  const updateVideo = (segment) => {
    if (!segment) return;
    videoRef.current.currentTime = segment.start;
  };

  const [vt, setVt] = useState(0);

  const isActive = (segment) => {
    if (!segment) return;
    if (segment.start < vt && segment.end > vt) {
      return "segment active";
    }
    return "segment";
  };

  // Debug this later
  //
  // const setScroll = () => {
  //   let transcript = document.getElementById("transcript");
  //   let tHeight = 25 * segments?.length;
  //   let vDuration = videoRef.current.duration;
  //   let playedInPx = tHeight * (videoRef.current.currentTime / vDuration);
  //   transcript.scrollTop = playedInPx;
  // };

  useEffect(() => {
    setInterval(() => {
      setVt(videoRef.current.currentTime);
      // setScroll();
    }, 500);
  }, [false]);

  return (
    <>
      <div className="video">
        <video src={video} autoPlay controls ref={videoRef} />
      </div>
      <div className="transcript" id="transcript">
        {segments?.map((segment, index) => {
          return (
            <div
              key={index}
              className={isActive(segment)}
              onClick={() => {
                updateVideo(segment);
              }}
            >
              {segment.text}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Video;
